// Component to display user preferences modal.

import './UserPreferences.css';
import { refreshUserInfo, useUserInfo } from 'helpers/useUserInfo';
import * as ReactiveForm from 'helpers/ReactiveForm';
import { makeEnhancedStore } from 'helpers/EnhancedStore';
import { makeSyncLocalEditsAction } from 'helpers/syncEditsAction';
import { queryApi } from 'helpers/apiQuery';
import { queryStateToStatus } from 'helpers/queryStateToStatus';
import { LocalChangesStatusDisplay } from 'components/Dashboard/LocalChanges';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';

// User preferences are stored in a global store because each page session
// is only ever tied to one user.
const useUserPreferencesStore = makeEnhancedStore({
    initStore: () => ({}),
    computeProperties: (state) => ({
        localChangesStatus: queryStateToStatus(state.syncQuery),
    }),
    actions: {
        async setLocalPreferenceEdits(localPreferenceEdits) {
            // Update state immediately to render form properly.
            this.setState({
                ...this.getState(),
                localPreferenceEdits,
            });
            await this.actions.syncLocalPreferenceEdits(localPreferenceEdits);
        },

        syncLocalPreferenceEdits: makeSyncLocalEditsAction({
            doSync: (localPreferenceEdits) =>
                queryApi('users/myinfo/set-preferences', {
                    method: 'POST',
                    json: localPreferenceEdits,
                }),
            updateCallback: function (syncQuery) {
                this.setState({
                    ...this.getState(),
                    syncQuery,
                });
            },
            onSync: refreshUserInfo,
        }),
    },
});

export function UserPreferences() {
    const { t } = useTranslation();
    const userInfo = useUserInfo();
    const userPreferencesStore = useUserPreferencesStore();

    const localPreferenceEdits =
        userPreferencesStore.localPreferenceEdits ?? userInfo.preferences;

    if (!localPreferenceEdits) {
        return t('loading');
    } else {
        return (
            <div>
                <ModalHeader title={t('preferences')} />
                <ReactiveForm.Form
                    state={localPreferenceEdits}
                    setState={
                        userPreferencesStore.actions.setLocalPreferenceEdits
                    }
                >
                    <div className="input-grid">
                        <label>Example Preference</label>
                        <ReactiveForm.Input fieldName="examplePreference" />
                    </div>
                    <div className="user-preferences-status-container">
                        <LocalChangesStatusDisplay
                            state={userPreferencesStore.localChangesStatus}
                        />
                    </div>
                </ReactiveForm.Form>
            </div>
        );
    }
}
