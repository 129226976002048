import { queryApi } from 'helpers/apiQuery';
import { useDebouncer } from 'helpers/debouncer';
import LoadingIcon from 'helpers/LoadingIcon.helpers';
import { useClickAway } from 'helpers/useClickAway';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function PatientSelectorPopup({ onCreate, onClose }) {
    const { t } = useTranslation();
    const popupRef = useRef();
    const firstNameRef = useRef();

    useClickAway(popupRef, onClose);

    useEffect(() => {
        firstNameRef.current.focus();
    }, []);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');

    const debounced = useDebouncer();
    const handleClickCreate = () => {
        debounced(async () => {
            const response = await queryApi('/patients/new', {
                method: 'POST',
                json: { firstName, lastName, age, gender },
            });
            const { patientId } = await response.json();
            onCreate({ patientId });
            onClose();
        });
    };

    const errorMessageKeyFromServer = debounced.status?.error?.cause?.body;
    const errorMessage =
        errorMessageKeyFromServer && t(errorMessageKeyFromServer);

    return (
        <div ref={popupRef} className="patient-selector-popup">
            <input
                ref={firstNameRef}
                placeholder={t('firstName')}
                value={firstName}
                onChange={(ev) => setFirstName(ev.target.value)}
            />
            <input
                placeholder={t('lastName')}
                value={lastName}
                onChange={(ev) => setLastName(ev.target.value)}
            />
            <input
                placeholder={t('age')}
                value={age}
                onChange={(ev) => setAge(ev.target.value)}
            />
            <input
                placeholder={t('gender')}
                value={gender}
                onChange={(ev) => setGender(ev.target.value)}
            />
            <button onClick={handleClickCreate}>{t('create')}</button>
            <span className="error-text">
                {errorMessage}
                {debounced.status?.isLoading && <LoadingIcon />}
            </span>
        </div>
    );
}
