import { useEffect } from 'react';

// Fire a callback whenever a DOM element beyond the supplied ref is clicked.
export function useClickAway(ref, callback) {
    useEffect(() => {
        const handleClick = (ev) => {
            if (!ref.current) return;
            if (!ref.current.contains(ev.target)) {
                callback();
            }
        };
        // Wait until the next animation frame to attach the listener.
        // This avoids bugs where the click that summons a modal
        // is also detected as a click away from the modal.
        requestAnimationFrame(() => {
            window.addEventListener('click', handleClick);
        });
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [ref, callback]);
}
