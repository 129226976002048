import { ZoomButton } from 'components/Dashboard/OutputCardHeader';

import './ModalErrorDisplay.css';

export function ModalErrorDisplay({ message }) {
    return (
        <>
            <div className="heading gap-10px dialogue-heading">
                <ZoomButton />
            </div>

            <span className="error-text">ERROR</span>
            <span>{message?.toString() ?? 'error'}</span>
        </>
    );
}
