import { useState } from 'react';

function applyUpdateCallback(func, updateCallback) {
    return async function (...args) {
        updateCallback({ isLoading: true });
        let data;
        try {
            data = await func(...args);
        } catch (error) {
            updateCallback({ error, isLoading: false });
            return;
        }
        updateCallback({ data, isLoading: false });
    };
}

// Debouncer that doesn't even recieve a function until call time.
export function useDebouncer(time = 200) {
    const [status, setStatus] = useState();

    let timer;
    const debounced = (func) => {
        setStatus({ isLoading: true });
        clearTimeout(timer);
        timer = setTimeout(applyUpdateCallback(func, setStatus), time);
    };
    debounced.status = status;
    return debounced;
}
