import React, { createContext, useContext, useState, useEffect } from 'react';

const placeholderSets = [
    {
        anamnesis:
            'i.e. The patient reports a two-week history of persistent cough and mild fever.',
        physical_examination:
            'i.e. On auscultation, diminished breath sounds noted in the lower right lung.',
        diagnosis:
            'i.e. Community-acquired pneumonia confirmed via chest X-ray.',
        treatment:
            'i.e. Prescribed a 7-day course of amoxicillin and advised rest.',
        epicrisis:
            'i.e. Patient recovered fully with no complications; follow-up in two weeks.',
    },
    {
        anamnesis:
            'i.e. Patient complains of intermittent chest pain during physical activity for the past month.',
        physical_examination:
            'i.e. Blood pressure recorded at 145/90 mmHg with a regular heart rate of 80 bpm.',
        diagnosis: 'i.e. Hypertension with suspected stable angina.',
        treatment:
            'i.e. Initiated low-dose aspirin and lifestyle modifications; referred for stress testing.',
        epicrisis:
            'i.e. Symptoms improved; patient adheres to medication and lifestyle changes.',
    },
    {
        anamnesis:
            'i.e. The patient reports frequent migraines, often triggered by bright lights.',
        physical_examination:
            'i.e. Neurological exam shows no focal deficits; patient appears in mild discomfort.',
        diagnosis: 'i.e. Chronic migraine with aura.',
        treatment:
            'i.e. Prescribed sumatriptan for acute episodes and recommended a migraine diary.',
        epicrisis:
            'i.e. Migraine frequency reduced by 50% after initiating therapy.',
    },
    {
        anamnesis:
            'i.e. Patient reports a sudden onset of severe abdominal pain localized to the lower right quadrant.',
        physical_examination:
            'i.e. Tenderness and guarding noted in the right lower abdomen; positive rebound test.',
        diagnosis: 'i.e. Acute appendicitis.',
        treatment:
            'i.e. Patient underwent laparoscopic appendectomy; intravenous antibiotics initiated.',
        epicrisis:
            'i.e. Discharged on postoperative day two with no complications; advised follow-up in one week.',
    },
];

// Function to calculate the current set index based on time
const getRandomPlaceholderSetIndex = async () => {
    const currentSecond = new Date().getSeconds();
    return currentSecond % placeholderSets.length; // Ensures the index wraps around
};

// Create the context
const PlaceholderContext = createContext();

// Provider for the context
export const PlaceholderProvider = ({ children }) => {
    // Persist the placeholder set for the session
    const [currentPlaceholders, setCurrentPlaceholders] = useState(null); // Start with null

    useEffect(() => {
        (async () => {
            const setIndex = await getRandomPlaceholderSetIndex();
            setCurrentPlaceholders(placeholderSets[setIndex]);
        })();
    }, []);

    if (!currentPlaceholders) {
        return <div></div>;
    }

    return (
        <PlaceholderContext.Provider value={currentPlaceholders}>
            {children}
        </PlaceholderContext.Provider>
    );
};

// Hook to access placeholders
export const usePlaceholder = (category) => {
    const placeholders = useContext(PlaceholderContext);
    if (!placeholders) {
        throw new Error(
            'usePlaceholder must be used within a PlaceholderProvider'
        );
    }
    if (!(category in placeholders)) {
        throw new Error(
            `Invalid category "${category}" passed to usePlaceholder. Valid categories are: ${Object.keys(placeholders).join(', ')}`
        );
    }
    return placeholders[category];
};
