import { useContext, useEffect, forwardRef } from 'react';
import { PatientContext } from 'components/Dashboard/PatientContext';
import LoadingIcon from 'helpers/LoadingIcon.helpers';
import { StreamingTranscribeButton } from 'helpers/TranscribeButton';
import './DialogueCard.css';
import { useDialogueController } from './DialogueController';
import { useSetModalRender } from 'components/Dashboard/ModalContext';
import { ModalErrorDisplay } from 'components/Header/ModalErrorDisplay';
import { useRefreshSuggestionTrigger } from 'helpers/RefreshSuggestions';

function unused() {}

const DialogueCardContents = forwardRef((props, ref) => {
    unused(props);
    const { state, dispatch } = useContext(PatientContext);

    const { patientId } = state;

    const setModalRender = useSetModalRender();
    const refreshSuggestionTrigger = useRefreshSuggestionTrigger();

    const {
        fetchTranscriptSufficiency,
        canProcessRecordings,
        processRecording,
        processRecordings,
        isProcessing,
    } = useDialogueController({
        patientId,
        onChangePhysicianInput: (newPhysicianInput) => {
            dispatch({
                type: 'SET_PHYSICIAN_INPUT',
                payload: newPhysicianInput,
            });
            refreshSuggestionTrigger();
        },
        onError: (error) => {
            let message = error;
            if (error?.cause?.body) {
                message = JSON.parse(error.cause.body).error;
            }
            setModalRender(() => <ModalErrorDisplay message={message} />);
        },
    });

    useEffect(() => {
        if (state.patientId) {
            fetchTranscriptSufficiency();
        }
    }, [fetchTranscriptSufficiency, state.patientId]);

    return (
        <div ref={ref} className="dialogue-widget">
            <StreamingTranscribeButton
                onTranscription={processRecording}
                disabled={!state.patientId}
            />
            {isProcessing && <LoadingIcon />}
            <button
                onClick={processRecordings}
                disabled={!canProcessRecordings || !state.patientId}
                className={`process-button ${canProcessRecordings ? 'active' : 'inactive'}`}
            >
                Process Recordings
            </button>
        </div>
    );
});

const DialogueCard = forwardRef((props, ref) => {
    return <DialogueCardContents {...props} ref={ref} />;
});

DialogueCard.displayName = 'DialogueCard';
DialogueCardContents.displayName = 'DialogueCardContents';

export default DialogueCard;
