// src/Header.js
import React from 'react';
import './Header.css';
import logo from '../../assets/logoFull.png';
import { PatientSelector } from '../Dashboard/PatientSelector';
import { AccountDetails } from './components/AccountDetails';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import { LocalChangesStatus } from '../Dashboard/LocalChanges';
import { OperationsMenu } from './components/OperationsMenu';
import DialogueCard from 'components/Header/components/DialogueCard/DialogueCard';
import { signOut } from 'supertokens-auth-react/recipe/session';

const Header = ({ showPatientSelector, showAccountDetails }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        localStorage.removeItem('token');
        await signOut();
        navigate('/login');
    };

    return (
        <div className="header-container">
            <img src={logo} alt="Logo" className="header-logo" />
            <div className="header-tabs-container">
                {showPatientSelector && <PatientSelector />}
                {showPatientSelector && <LocalChangesStatus />}
                <DialogueCard />
                <OperationsMenu />
            </div>
            <div className="header-account-container">
                <LanguageSwitcher />
                {showAccountDetails && (
                    <AccountDetails onLogout={handleLogout} />
                )}
            </div>
        </div>
    );
};

export default Header;
