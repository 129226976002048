// Controller pattern for audio recorder. Can be hoisted up into shared state.

import { memoize } from 'helpers/memoize';
import {
    computedProperty,
    Observable,
    useObservable,
} from 'helpers/Observable';
import { withBottleneck } from 'helpers/withMutex';
import {
    STATE_PAUSED,
    STATE_RECORDING,
    STATE_INACTIVE,
    STATE_PROCESSING,
} from './state';

const now = () => +new Date();
class TimerController {
    constructor() {
        this.state = new Observable(STATE_INACTIVE);
        this.commitedMs = 0;
        this.recordStart = undefined;
    }
    start() {
        this.state.update(STATE_RECORDING);
        this.commitedMs = 0;
        this.recordStart = now();
    }
    resume() {
        this.state.update(STATE_RECORDING);
        this.recordStart = now();
    }
    pause() {
        if (this.state.get() === STATE_RECORDING) {
            this.commitedMs += now() - this.recordStart;
            this.recordStart = undefined;
        }
        this.state.update(STATE_PAUSED);
    }
    getCurrentMs() {
        let totalMs = this.commitedMs;
        if (this.recordStart) {
            totalMs += now() - this.recordStart;
        }
        return totalMs;
    }
}

// Decorator to error out if media recorder is not set.
// Used by methods of `AudioRecorderController`.
const requiresMediaRecorder = (func) =>
    function (...args) {
        if (!this.mediaRecorder) {
            throw new Error('Media recorder required');
        }
        return func(...args);
    };

const bind = (func) => func.bind(this);

class AudioRecorderController {
    constructor() {
        this._mediaRecorder = new Observable();
        this.MediaRecorderClass = MediaRecorder;
        this.state = new Observable(STATE_INACTIVE);
        this.timeSliceMs = 15_000;
        this.timerController = new TimerController();
        this.handlers = undefined;
        this.makeHandlers = undefined;

        this.shouldCallOnRecordingComplete = true;
    }

    setMakeHandlers(makeHandlers) {
        this.makeHandlers = makeHandlers;
    }

    setTimeSliceMs(value) {
        this.timeSliceMs = value;
    }

    get mediaRecorder() {
        return this._mediaRecorder.get();
    }
    set mediaRecorder(value) {
        this._mediaRecorder.update(value);
    }

    ready = memoize(() => {
        return computedProperty(() => true, []);
    });

    setHandlers(handlers) {
        this.handlers = handlers;
    }

    setMediaRecorderClass(MediaRecorderClass) {
        this.MediaRecorderClass = MediaRecorderClass;
    }

    handleDataAvailable = bind((ev) => {
        this.handlers.onChunkComplete(ev.data);
    });

    handleStop = bind(async () => {
        if (this.shouldCallOnRecordingComplete) {
            const promise = this.handlers.onRecordingComplete();
            if (promise) {
                this.state.update(STATE_PROCESSING);
                try {
                    await promise;
                } finally {
                    this.state.update(STATE_INACTIVE);
                }
            } else {
                this.state.update(STATE_INACTIVE);
            }
        }
    });

    acquireMediaRecorder = withBottleneck(async () => {
        if (this.mediaRecorder) return;
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });
        this._mediaRecorder.update(
            new this.MediaRecorderClass(stream, { mimeType: 'audio/webm' })
        );
        this._mediaRecorder.get().stream = stream;
        this._mediaRecorder
            .get()
            .addEventListener('dataavailable', this.handleDataAvailable);
        this._mediaRecorder.get().addEventListener('stop', this.handleStop);
    });

    playPause = requiresMediaRecorder(() => {
        if (this.state.get() === STATE_RECORDING) {
            this.timerController.pause();
            this.mediaRecorder.pause();
            this.state.update(STATE_PAUSED);
        } else if (this.state.get() === STATE_PAUSED) {
            this.mediaRecorder.resume();
            this.timerController.resume();
            this.state.update(STATE_RECORDING);
        }
    });

    cancel = requiresMediaRecorder(() => {
        this.shouldCallOnRecordingComplete = false;
        this.state.update(STATE_INACTIVE);

        if (this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        this.mediaRecorder.stop();
        this._mediaRecorder.update(null);
    });

    save = requiresMediaRecorder(() => {
        this.shouldCallOnRecordingComplete = true;
        this.state.update(STATE_INACTIVE);

        if (this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        this.mediaRecorder.stop();
        this._mediaRecorder.update(null);
    });

    startRecord = requiresMediaRecorder(() => {
        this.handlers = this.makeHandlers();
        this.mediaRecorder.start(this.timeSliceMs);
        this.timerController.start();
        this.state.update(STATE_RECORDING);
        this.shouldCallOnRecordingComplete = true;
    });
}

const audioRecorderController = new AudioRecorderController();
window.audioRecorderController = audioRecorderController;

// Hook for audio recorder controls.
export function useAudioRecorderController({
    MediaRecorderClass = MediaRecorder,
    timeSliceMs,
    makeHandlers,
}) {
    const controller = audioRecorderController;
    controller.setMediaRecorderClass(MediaRecorderClass);
    controller.setTimeSliceMs(timeSliceMs);
    controller.setMakeHandlers(makeHandlers);

    return {
        state: useObservable(controller.state),
        playPause: controller.playPause.bind(controller),
        cancel: controller.cancel.bind(controller),
        save: controller.save.bind(controller),
        startRecord: async () => {
            // Once this is triggered, the user will be prompted to allow
            // microphone access, and the recording icon will appear.
            await controller.acquireMediaRecorder();
            controller.startRecord();
        },
        setHandlers: controller.setHandlers.bind(controller),
        ready: useObservable(controller.ready()),
    };
}

// Hook for current time display. Separate because it updates much more often.
export function useAudioRecorderTimerController() {
    const controller = audioRecorderController.timerController;

    return {
        current: controller.getCurrentMs.bind(controller),
    };
}
