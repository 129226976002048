import { useContext } from 'react';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import { FaCopy } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { ImQuill } from 'react-icons/im';
import { FaExpandAlt } from 'react-icons/fa';
import { CopyIconDisplay, useCopy } from './CopyIcon';
import { OutputCardContext } from './OutputCardContext';
import { useCloseModal, useInModal, useSummonModal } from './ModalContext';
import Tooltip from './Tooltip';

export function ContextualLoadingIcon() {
    const { query } = useContext(OutputCardContext);

    return query.isLoading && <LoadingIcon />;
}

export function SuggestButton({ onClick }) {
    return (
        <span className="pallet-icon" onClick={onClick}>
            <Tooltip text="suggest">
                <ImQuill />
            </Tooltip>
        </span>
    );
}

//is this being used?
export function CopyButton({ onClick }) {
    return (
        <span className="pallet-icon" onClick={onClick}>
            <Tooltip text="copy">
                <FaCopy />
            </Tooltip>
        </span>
    );
}

export function ZoomButton() {
    const inModal = useInModal();
    const summonModal = useSummonModal();
    const closeModal = useCloseModal();

    const handleClick = () => {
        if (inModal) {
            closeModal();
        } else {
            summonModal();
        }
    };

    return (
        <span className="pallet-icon" onClick={handleClick}>
            {inModal ? (
                <Tooltip text="close">
                    <IoClose
                        style={{ fontSize: 'var(--size-content-medium)' }}
                    />
                </Tooltip>
            ) : (
                <Tooltip text="expand">
                    <FaExpandAlt />
                </Tooltip>
            )}
        </span>
    );
}

export const OutputCardHeader = ({ title, loading, suggest, copyText }) => {
    const { copiedRecently, handleClickCopy } = useCopy({ copyText });

    let labelClassName = 'copy-icon-label';
    if (!copiedRecently) labelClassName += ' invisible';

    return (
        <div className="output-card-header heading">
            <span className="flex align-items-center gap-10px">
                <span>{title}</span>
                {loading && <LoadingIcon />}
            </span>
            <span className={labelClassName}>Copied!</span>
            <span className="pallet">
                {suggest && (
                    <SuggestButton
                        onClick={() => {
                            suggest();
                        }}
                    />
                )}
                <CopyIconDisplay
                    copiedRecently={copiedRecently}
                    onClick={handleClickCopy}
                />
                <ZoomButton />
            </span>
        </div>
    );
};

// Like OutputCardHeader, but pulls props from context.
export function ContextualOutputCardHeader(props) {
    const { title, query, generateSuggestion, data, category } =
        useContext(OutputCardContext);

    return (
        <OutputCardHeader
            title={title}
            loading={query.isLoading}
            suggest={generateSuggestion}
            copyText={data}
            category={category}
            {...props}
        />
    );
}

// Like SuggestButton, but pulls props from context.
export function ContextualSuggestButton() {
    const { generateSuggestion } = useContext(OutputCardContext);

    return <SuggestButton onClick={generateSuggestion} />;
}
