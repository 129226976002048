// Object to handle state, computed properties,
// and effects/actions.
//
// A store is composed of a few pieces:
// -- A zustand store for state
// -- A set of computed properties
// -- A set of actions to mutate the whole hitng.

import { useRef } from 'react';
import { create as zustandCreate } from 'zustand';

export function makeEnhancedStore({ initStore, computeProperties, actions }) {
    const useStore = zustandCreate(initStore);

    const useEnhancedStore = (...args) => {
        if (args.length > 1) {
            console.warn(
                'Selector function not supported by custom store ' +
                    'at the moment'
            );
        }

        const state = useStore();

        return {
            ...state,
            ...(computeProperties?.(state) ?? {}),
            actions: useEnhancedStore.actions,
        };
    };

    useEnhancedStore.getState = useStore.getState.bind(useStore);
    useEnhancedStore.setState = useStore.setState.bind(useStore);

    useEnhancedStore.getProperties = (selector) =>
        computeProperties(useStore.getState(selector));

    // Locals are a place to put non-reactive state.
    // Stuff like timeouts and custom JS objects make sense here.
    useEnhancedStore.locals = {};

    if (actions) {
        useEnhancedStore.actions = Object.fromEntries(
            Object.entries(actions).map(([name, action]) => [
                name,
                action.bind(useEnhancedStore),
            ])
        );
    }

    return useEnhancedStore;
}

function useFactoryRef(makeInitialState) {
    const ref = useRef();
    if (!ref.current) ref.current = makeInitialState();
    return ref;
}

// Use an enhanced store, scoped to a react component.
// Useful for hosting a logic within a react component,
// but leaving ourselves open to moving state upwards if needed.
export function useEnhancedLocalStore(config) {
    const useStore = useFactoryRef(() => makeEnhancedStore(config)).current;
    return useStore();
}
