import React from 'react';
import './OperationsMenu.css';
import { FaToolbox } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { withProvider } from 'helpers/withProvider';
import { Dropdown, DropdownProvider, useDropdown } from 'helpers/Dropdown';
import { useSetModalRender } from 'components/Dashboard/ModalContext';
import { FreeTextEditor } from 'components/PracticeDashboard/FreeTextEditor';
import { TranscriptionCard } from 'components/Header/components/DialogueCard/DialogueTranscription';

export const OperationsMenu = withProvider(() => {
    const { t } = useTranslation();

    const dropdown = useDropdown();
    const setModalRender = useSetModalRender();

    return (
        <div className="operations-menu-container">
            <button
                className="operations-menu-button"
                onClick={dropdown.toggleOpen}
            >
                <FaToolbox />
            </button>
            <Dropdown
                items={[
                    {
                        label: 'See Transcription',
                        callback: () => {
                            setModalRender(() => <TranscriptionCard />);
                        },
                    },
                    {
                        label: t('freeTextEditor'),
                        callback: () => {
                            setModalRender(() => <FreeTextEditor />);
                        },
                    },
                ]}
            />
        </div>
    );
}, DropdownProvider);
