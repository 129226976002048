import React from 'react';
import OutputCard from '../Dashboard/OutputCard';
import DiagnosisCard from './DiagnosisCard/DiagnosisCard';

const CARD_CONFIGS = [
    {
        className: 'anamnesis-card',
        Component: OutputCard,
        props: {
            showHeader: true,
            titleKey: 'anamnesis',
            category: 'anamnesis',
        },
    },
    {
        className: 'physical-examination-card',
        Component: OutputCard,
        props: {
            showHeader: true,
            titleKey: 'physicalExamination',
            category: 'physical_examination',
        },
    },
    {
        className: 'diagnosis-card',
        Component: DiagnosisCard,
        // While this prop is technically useless,
        // we re-use the "props.category" field
        // to index card configs by category
        // so we set the prop anyway.
        props: {
            showHeader: true,
            titleKey: 'diagnosis',
            category: 'diagnosis',
        },
    },
    {
        className: 'treatment-card',
        Component: OutputCard,
        props: {
            showHeader: true,
            titleKey: 'treatment',
            category: 'treatment',
        },
    },
    {
        className: 'epicrisis-card',
        Component: OutputCard,
        props: {
            showHeader: true,
            titleKey: 'epicrisis',
            category: 'epicrisis',
        },
    },
];

export const CARD_CATEGORIES = CARD_CONFIGS.map((card) => card.props.category);

function createCardElement(t, refs, card, key) {
    return React.createElement(card.Component, {
        ...card.props,
        title: card.props.titleKey ? t(card.props.titleKey) : '',
        key,
        ref: refs.get(card.props.category),
    });
}

export function createCardElements(t, refs) {
    return CARD_CONFIGS.map((card, idx) =>
        createCardElement(t, refs, card, idx)
    );
}
