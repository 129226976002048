// Transform a func into a wrapped func
// that only fires after `time`  ms,
// and only fires after bouncing stops.
export function debounce(func, time = 200) {
    let timer;
    const wrappedFunc = (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), time);
    };

    // If the caller tries to bind the wrapped function,
    // they are actually trying to bind the inner function.
    // Therefore, we apply `.bind()` to the inner function instead.
    wrappedFunc.bind = (...args) => {
        func = func.bind(...args);
        return wrappedFunc;
    };

    return wrappedFunc;
}

// Variant that does not wait for bouncing to stop.
// Always returns undefined, because the caller shouldn't
// rely on getting a value back. The function might be queued up after all.
export function debounceEager(func, time = 200) {
    let lastCalledAt;
    let timer;

    return (...args) => {
        const callTime = performance.now();

        if (lastCalledAt) {
            // If enough time has passed,
            // immediately call the underlying function.
            if (callTime - lastCalledAt > time) {
                lastCalledAt = callTime;
                func(...args);
            } else {
                // If insufficient time has passed,
                // then queue a timer to call the function soon.
                // But if timer is already queued,
                // then do nothing.
                // Note that we could update the timer to use the latest args,
                // but we don't have a use-case for that feature at the moment.
                if (!timer) {
                    timer = setTimeout(() => {
                        lastCalledAt = performance.now();
                        func(...args);
                        timer = undefined;
                    }, time);
                }
            }
        } else {
            // If this is the first call,
            // then call the underlying function.
            lastCalledAt = callTime;
            func(...args);
        }
    };
}
