import { useCallback, useEffect, useRef, useState } from 'react';

// Hook for a pausable, pollable timer.
export function useTimer() {
    const ref = useRef({
        // Milliseconds committed into the timer
        // from previous play/pause pairs.
        committedMs: 0,
        // Timestamp of most recent record click.
        // Used to compute the most recent portion
        // of the time on demand.
        recordStart: undefined,
    });

    // Use callback for current() to avoid unnecessary re-renders
    // with useEffect() in a downstream component.
    const current = useCallback(() => {
        const { committedMs, recordStart } = ref.current;
        let t = committedMs;
        if (recordStart) {
            t += new Date() - recordStart;
        }
        return t;
    }, []);

    // Return a method to compute the current milliseconds,
    // alongside start/resume/pause commands.
    return {
        current: current,
        start: () => {
            ref.current.committedMs = 0;
            ref.current.recordStart = new Date();
        },
        resume: () => {
            ref.current.recordStart = new Date();
        },
        pause: () => {
            if (!ref.current.recordStart) {
                console.warn('Pausing paused timer');
                return;
            }
            ref.current.committedMs += new Date() - ref.current.recordStart;
            ref.current.recordStart = undefined;
        },
    };
}

// We use `setTimeout()` instead of `setInterval()` here to avoid
// event catch up on tab focus.
export function useTimerCurrentTime({ getTimeMs, pollMs }) {
    const [ms, setMs] = useState(getTimeMs());
    const timeoutRef = useRef();

    useEffect(() => {
        const handleTimeout = () => {
            setMs(getTimeMs());
            // After each poll, queue another poll.
            timeoutRef.current = setTimeout(handleTimeout, pollMs);
        };

        // Start polling.
        timeoutRef.current = setTimeout(handleTimeout, pollMs);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [getTimeMs, pollMs]);

    return ms;
}
