// src/Header.js
import React from 'react';
import './WebsiteHeader.css';
import logo from '../../../../assets/logoFull.png';
import { LoginContainer } from './components/LoginContainer';
import { useNavigate } from 'react-router-dom';
import { GlobalErrorDisplay } from '../../../Dashboard/GlobalErrorDisplay';

const WebsiteHeader = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className="web-header-container">
            <img src={logo} alt="Logo" className="header-logo" />
            <div className="web-header-tabs-container">
                <GlobalErrorDisplay />
            </div>
            <div className="web-header-account-container">
                <LoginContainer onLogout={handleLogout} />
            </div>
        </div>
    );
};

export default WebsiteHeader;
