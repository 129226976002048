import { createContext, useContext, useState } from 'react';

import { useEscapeKey } from '../../helpers/useEscapeKey';
import { PersistProvider, usePersistTrigger } from '../../helpers/usePersist';
import { withProvider } from '../../helpers/withProvider';

const ModalContext = createContext();

// Provider component that binds multiple possible modal views
// into one modal DOM element. Just glue.
export function ModalProvider({ children }) {
    const [render, setRender] = useState();

    return (
        <ModalContext.Provider value={{ render, setRender }}>
            {children}
        </ModalContext.Provider>
    );
}

export function useSetModalRender() {
    const { setRender } = useContext(ModalContext);
    return (render) => {
        setRender(() => render);
    };
}

const SummonModalContext = createContext();

// Provider that exists within a specific <ModalRender> instance.
// This provides access the `summonModal()` function without prop-drilling.
function ModalRenderTargetProvider({ children, summonModal }) {
    return (
        <SummonModalContext.Provider value={summonModal}>
            {children}
        </SummonModalContext.Provider>
    );
}

// Wrapper component that renders a component,
// and clones it into the modal when `summonModal()` is called.
export const ModalRenderTarget = withProvider(({ render }) => {
    const { setRender } = useContext(ModalContext);
    const persistTrigger = usePersistTrigger();

    const summonModal = () => {
        persistTrigger();
        // Use arrow function to bypass react behaviour for
        // setState(func).
        setRender(() => render);
    };

    return (
        <ModalRenderTargetProvider summonModal={summonModal}>
            {render()}
        </ModalRenderTargetProvider>
    );
}, PersistProvider);

// Hook which provides a function to summon (open) the modal.
// Sets ModalDisplay = ModalRenderTarget basically.
// Can only be called within a <ModalRenderTarget> component.
export function useSummonModal() {
    return useContext(SummonModalContext);
}

// Returns a boolean indicating whether the caller
// is in a modal. Otherwise, its displaying as a regular card.
export function useInModal() {
    const modalContext = useContext(ModalContext);
    const summonModalContext = useContext(SummonModalContext);

    return Boolean(modalContext && !summonModalContext);
}

// Returns a function which closes the modal.
export function useCloseModal() {
    const { setRender } = useContext(ModalContext);
    const triggerPersist = usePersistTrigger();

    return () => {
        triggerPersist();
        setRender(undefined);
    };
}

// Component to display the active modal, if any.
function ModalDisplayContents() {
    const { render, setRender } = useContext(ModalContext);
    const triggerPersist = usePersistTrigger();

    const handleClickBg = (ev) => {
        // Only close modal if the background div itself is clicked,
        // rather than a child DOM element.
        if (ev.target.className === 'output-card-modal-bg') {
            triggerPersist();
            setRender(undefined);
        }
    };

    useEscapeKey(() => {
        triggerPersist();
        setRender(undefined);
    });

    return (
        render && (
            <div className="output-card-modal-bg" onClick={handleClickBg}>
                <div className="card output-card-modal">{render?.()}</div>
            </div>
        )
    );
}

// Wrap the exported modal display in a <PersistProvider>.
// This is required because the modal display itself,
// as well as descendant components must all be able
// to trigger a persistence operation to sync cards.
export function ModalDisplay() {
    return (
        <PersistProvider>
            <ModalDisplayContents />
        </PersistProvider>
    );
}
