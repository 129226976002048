import React, { useContext, useRef, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import './OutputCard.css';
import { PatientContext } from './PatientContext';
import { ContextualOutputCardHeader } from './OutputCardHeader';
import { useApiQuery } from '../../helpers/useApiQuery';
import { markdownToText } from '../../helpers/markdownToText';
import { OutputCardContext } from './OutputCardContext';
import { ModalRenderTarget } from './ModalContext';
import { throwsNamedError } from '../../helpers/withErrorAnnotation';
import { useSwapEffect } from '../../helpers/useSwapEffect';
import { usePlaceholder } from './PlaceholderContext';
import { Textarea } from 'helpers/Textarea';

export function OutputCardBody() {
    const { category, data, onChange, suggestion } =
        useContext(OutputCardContext);

    // Fetch the placeholder for the current category
    const placeholder = usePlaceholder(category);

    return (
        <div className={`scroll-container`}>
            <Textarea
                data-auto-resize="false"
                value={data || ''}
                onChange={onChange}
                readOnly={Boolean(suggestion)}
                placeholder={placeholder}
            />
        </div>
    );
}

export function OutputCardFooter() {
    const { suggestion, setSuggestion, setData } =
        useContext(OutputCardContext);

    const handleClickAccept = () => {
        setData(suggestion);
        setSuggestion(undefined);
    };

    const handleClickDismiss = () => {
        setSuggestion(undefined);
    };

    return (
        suggestion && (
            <div>
                <button onClick={handleClickAccept}>Accept</button>
                <button onClick={handleClickDismiss}>Dismiss</button>
            </div>
        )
    );
}

// Map of (category->struct) which describes how to handle dispatches,
// server reads, and server writes for various fields.
const CATEGORY_KEYS = {
    treatment: {
        dispatchType: 'SET_TREATMENT',
        field: 'treatment',
        url: (patientId) => `/patients/${patientId}/treatment`,
        message: (data) => data.treatment.message,
    },
    diagnosis: {
        dispatchType: 'SET_DIAGNOSIS_NOTES',
        field: 'diagnosisNotes',
        url: (patientId) => `/patients/${patientId}/diagnosis`,
        message: (data) => data.diagnosis.message,
    },
    epicrisis: {
        dispatchType: 'SET_EPICRISIS',
        field: 'epicrisis',
        url: (patientId) => `/patients/${patientId}/epicrisis`,
        message: (data) => data.epicrisis.message,
    },
    anamnesis: {
        dispatchType: 'SET_ANAMNESIS',
        field: 'anamnesis',
        url: (patientId) => `/patients/${patientId}/anamnesis`,
        message: (data) => data.anamnesis.message,
    },
    physical_examination: {
        dispatchType: 'SET_PHYSICAL_EXAMINATION_NOTES',
        field: 'physicalExaminationNotes',
        url: (patientId) => `patients/${patientId}/physical-examination/`,
        message: (data) => data.physicalExaminationNotes.message,
    },
};

// Parent component that provides context used by header,footer,body.
// Useful for building alternate views of an OutputCard.
export function OutputCardProvider({ children, title, category, ...props }) {
    const { state, dispatch } = useContext(PatientContext);
    const { i18n } = useTranslation();
    const [suggestion, setSuggestionState] = useState(undefined);
    const prevSuggestionRef = useRef();
    const query = useApiQuery();

    const setSuggestion = (value) => {
        // If there is a suggestion currently,
        // store it as the previous suggestion.
        if (suggestion) {
            prevSuggestionRef.current = suggestion;
        }

        setSuggestionState(value);
    };

    const userText = state.physicianInput[CATEGORY_KEYS[category].field];

    // Every time the language changes, submit a query to translate the field.
    useSwapEffect({
        invariant: userText,
        variant: i18n.language,
        callback: () => {
            query.cancel();
            setSuggestion(null);
            query.submit({
                method: 'POST',
                path: '/translate',
                body: {
                    toLanguage: i18n.language,
                    userText: userText,
                },
                callback: (data) => {
                    if (data?.translated) {
                        setSuggestion(data.translation);
                    }
                },
            });
        },
    });

    const { patientId } = state;

    const { dispatchType, field } =
        CATEGORY_KEYS[category] ?? CATEGORY_KEYS.treatment;

    const setData = (value) => {
        dispatch({
            type: dispatchType,
            payload: value,
        });
    };

    const handleChange = (ev) => {
        dispatch({
            type: dispatchType,
            payload: ev.target.value,
        });
    };

    const generateSuggestion = (options = {}) => {
        const url = CATEGORY_KEYS[category].url(patientId);

        query.submit({
            method: 'POST',
            path: url,
            body: {
                userText: state.physicianInput[field],
                // Include the latest physician input state
                // such that user text is taken into account
                // even if it hasn't been saved yet.
                physicianInput: state.physicianInput,
                ...options,
            },
            callback: throwsNamedError(`error.${category}`, (data) => {
                if (!data) return;

                // For generate requests, we should actually ignore cache hits.
                // We only want the suggestion if it is updated.
                if (options.cacheKey === 'generate' && data.cacheHit) {
                    return;
                }
                const message = CATEGORY_KEYS[category].message(data);

                const suggestion = markdownToText(message);
                if (suggestion !== prevSuggestionRef.current) {
                    setSuggestion(suggestion);
                }
            }),
        });
    };

    /*const diagnosesText =
        state.physicianInput.diagnoses
            .map((x) => `${x.code} -- ${x.codeBezeichnung}`)
            .join('\n') +
        '\n\n' +
        userText;*/

    const displayData = suggestion || state.physicianInput[field];

    return (
        <OutputCardContext.Provider
            value={{
                title,
                query,
                data: displayData,
                setData,
                patientId,
                onChange: handleChange,
                suggestion,
                setSuggestion,
                generateSuggestion,
                category,
                ...props,
            }}
        >
            {children}
        </OutputCardContext.Provider>
    );
}

const OutputCard = forwardRef((props, ref) => {
    return (
        <ModalRenderTarget
            render={() => (
                <OutputCardProvider
                    title={props.title}
                    category={props.category}
                    showHeader={props.showHeader}
                >
                    <div className="card output-card-container" ref={ref}>
                        <div
                            className={
                                props.showHeader
                                    ? 'output-card'
                                    : 'output-card-no-header'
                            }
                        >
                            {props.showHeader && <ContextualOutputCardHeader />}
                            <OutputCardBody />
                            <OutputCardFooter />
                        </div>
                    </div>
                </OutputCardProvider>
            )}
        />
    );
});

OutputCard.displayName = 'OutputCard';

export default OutputCard;
