import React, { useState } from 'react';
import './Tooltip.css'; // Import your CSS file

const Tooltip = ({ children, text }) => {
    const [show, setShow] = useState(false);

    return (
        <div
            className="tooltip"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            {children}
            <div className={`tooltip-text ${show ? 'show' : ''}`}>{text}</div>
        </div>
    );
};

export default Tooltip;
