// Object to hold values that need to be reactive,
// but might be convenient to update outside of react.

import { useEffect, useState } from 'react';

export class Observable {
    constructor(initialValue) {
        this._observers = [];
        this._value = initialValue;
    }

    addListener(listener) {
        this._observers.push(listener);
    }

    removeListener(listener) {
        this._observers = this._observers.filter((x) => x !== listener);
    }

    get() {
        return this._value;
    }

    update(value) {
        this._value = value;
        for (const observer of this._observers) {
            observer(value);
        }
    }
}

// Use an observable value from react. Returns a read-only value.
export function useObservable(observable) {
    const [value, setValue] = useState(observable.get());

    useEffect(() => {
        observable.addListener(setValue);
        return () => {
            observable.removeListener(setValue);
        };
    }, [observable]);

    return value;
}

// Compute a property from observables, which is itself observable.
// Provide a list of observable inputs
// alongside the function that depends on them.
export function computedProperty(func, inputs) {
    const property = new Observable(func());

    const handleUpdate = () => {
        property.update(func());
    };

    for (const input of inputs) {
        input.addListener(handleUpdate);
    }

    return property;
}
