import React from 'react';
import Header from '../Header';
import { PatientProvider } from './PatientContext';
import './Dashboard.css';
import { RefreshSuggestionProvider } from '../../helpers/RefreshSuggestions';
import { ModalProvider } from './ModalContext';
import { GlobalErrorDisplay } from './GlobalErrorDisplay';

export function DashboardContainer({ children }) {
    return (
        <PatientProvider>
            <RefreshSuggestionProvider>
                <ModalProvider>
                    <div className="dashboard">
                        <Header
                            showPatientSelector={true}
                            showAccountDetails={true}
                        />
                        {children}
                        <GlobalErrorDisplay />
                    </div>
                </ModalProvider>
            </RefreshSuggestionProvider>
        </PatientProvider>
    );
}
