export const memoize = (func) => {
    let returnValue;
    return (...args) => {
        if (args.length > 0) {
            throw new Error('`memoize()` only works on 0-arg functions');
        }
        if (!returnValue) {
            returnValue = func();
        }
        return returnValue;
    };
};
