import {
    STATE_ERROR,
    STATE_NOCHANGE,
    STATE_SAVED,
    STATE_SAVING,
} from 'components/Dashboard/LocalChanges';

export function queryStateToStatus(state) {
    if (!state) return STATE_NOCHANGE;
    if (state.isLoading) return STATE_SAVING;
    if (state.data) return STATE_SAVED;
    return STATE_ERROR;
}
