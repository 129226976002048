import React, { useState } from 'react';
import './DashboardLogin.css';
import { useNavigate } from 'react-router-dom';
import { useApiQuery } from '../../helpers/useApiQuery';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../Header/LanguageSwitcher';
import { signInClicked } from './signin';
import InputField from 'helpers/InputField';

const DashboardLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');

    const navigate = useNavigate();

    const query = useApiQuery();

    const { t } = useTranslation();

    const handleLogin = async () => {
        signInClicked(email, password, setErrorText);
    };

    const handleRegistration = () => {
        navigate('/register');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="login-wrapper">
            <div className="login-header">
                <LanguageSwitcher />
            </div>
            <div className="login-column">
                <span className="heading login-title">{t('login')}</span>
                <InputField
                    label={t('email')}
                    value={email}
                    onChange={(newValue) => setEmail(newValue)}
                    onKeyDown={handleKeyPress}
                />
                <InputField
                    label={t('password')}
                    type="password"
                    value={password}
                    onChange={(newValue) => setPassword(newValue)}
                    onKeyDown={handleKeyPress}
                />
                <span className="flex align-items-center">
                    <button onClick={handleLogin}>{t('login')}</button>
                    <LoadingIcon
                        className={query.isLoading ? '' : 'invisible'}
                    />
                </span>
                {errorText && (
                    <span className="error-text">{t(errorText)}</span>
                )}
                <div className="register-container">
                    <span>{t('doNotHaveAnAccountYet')}</span>
                    <button onClick={handleRegistration}>
                        {t('registerHere')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DashboardLogin;
