import i18next from 'i18next';
import { serverUrl } from '../config';

async function queryApiCore(path, options) {
    options = options ?? {};
    options.credentials = options.credentials ?? 'include';

    const url = new URL(`${serverUrl}/${path}`);
    url.searchParams.set('language', i18next.language);

    if (options?.json) {
        options.body = JSON.stringify(options.json);
        options.headers = options.headers ?? {};
        options.headers['Content-Type'] = 'application/json';
    }

    const response = await fetch(url, options);

    if (options?.throwOnStatus !== false && !response.ok) {
        let responseText = '';
        try {
            responseText = await response.text();
        } catch (error) {
            console.warn(error);
        }
        throw new Error(`HTTP status ${response.status}`, {
            cause: {
                body: responseText,
            },
        });
    }

    return response;
}

// Wrap the `queryApi()` function in a handler that consumes
// the "updateCallback" option. This callback is fired with
// {data,error,isLoading} whenever any of those states are updated.
// Designed to be piped into some kind of reactive state.
const withUpdateCallback = (queryFunc) => async (path, options) => {
    const callback = options?.updateCallback;

    if (!options?.updateCallback) {
        return await queryFunc(path, options);
    }

    let queryData = {
        isLoading: true,
        data: undefined,
        error: undefined,
    };
    // We are consuming callback arg here, so omit it from the core API options.
    // If they are even provided, that is.
    if (options) {
        delete options.updateCallback;
    }
    callback(queryData);

    let responseJson;
    try {
        const responseObject = await queryFunc(path, options);
        responseJson = await responseObject.json();
    } catch (error) {
        queryData = { ...queryData, error, isLoading: false };
        callback(queryData);
    }

    queryData = { ...queryData, data: responseJson, isLoading: false };
    callback(queryData);
};

export const queryApi = withUpdateCallback(queryApiCore);

// When updating a query's state, we sometimes want to keep the previous data
// to prevent flickering.
export function keepPrevQueryData(prevQueryState, newQueryState) {
    return {
        ...newQueryState,
        data: newQueryState.isLoading
            ? prevQueryState?.data
            : newQueryState.data,
    };
}
