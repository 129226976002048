import React from 'react';
import './InputField.css';

const InputField = ({
    label = undefined,
    type = 'text',
    value,
    placeholder = '',
    onChange,
    onKeyDown = () => {},
}) => {
    return (
        <div className="input-wrapper">
            {label && <label>{label}</label>}
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onKeyDown}
            />
            <span className="underline"></span>
            <span className="phantom-underline"></span>
        </div>
    );
};

export default InputField;
