import { createContext, useContext, useRef, useState } from 'react';
import { useClickAway } from './useClickAway';
import './Dropdown.css';

const Context = createContext();

export function DropdownProvider({ children }) {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
        ignoreRef.current = true;
        requestAnimationFrame(() => {
            ignoreRef.current = false;
        });
    };

    // Ref used to ignore click events for a single animation frame.
    const ignoreRef = useRef(false);

    return (
        <Context.Provider value={{ open, setOpen, toggleOpen, ignoreRef }}>
            {children}
        </Context.Provider>
    );
}

export function useToggleDropdown() {
    return useContext(Context)?.toggleOpen;
}

export function useDropdown() {
    return useContext(Context);
}

export function Dropdown({ items }) {
    const { open, setOpen, toggleOpen, ignoreRef } = useContext(Context);
    const ref = useRef();

    useClickAway(ref, () => {
        if (!ignoreRef.current) {
            setOpen(false);
        }
    });

    const handleClick = (item) => (ev) => {
        item.callback(ev);
        setOpen(false);
    };

    return (
        open && (
            <div ref={ref} className="dropdown-menu" onClick={toggleOpen}>
                {items.map((item, idx) => (
                    <button key={idx} onClick={handleClick(item)}>
                        {item.label}
                    </button>
                ))}
            </div>
        )
    );
}
