import { keepPrevQueryData, queryApi } from './apiQuery';
import { makeEnhancedStore } from './EnhancedStore';

export const userInfoStore = makeEnhancedStore({
    initStore: () => ({}),
    computeProperties: (state) => ({
        features: state.devFeatureFlags ?? state.query?.data?.features,

        // Aliases.
        firstName: state.query.data?.firstName ?? '',
        lastName: state.query.data?.lastName ?? '',
        role: state.query.data?.role ?? '',
        title: state.query.data?.title ?? '',
        preferences: state.query.data?.preferences,
    }),
});
export const useUserInfo = userInfoStore;

// Note that we could retry this periodically if we wanted.
export async function refreshUserInfo() {
    await queryApi('users/myinfo/me', {
        updateCallback: (query) =>
            userInfoStore.setState({
                ...userInfoStore.getState(),
                query: keepPrevQueryData(userInfoStore.getState().query, query),
            }),
    });
}

refreshUserInfo(); // Run on init.

// Function for debug usage. When dev feature flags are defined,
// they are taken as truth. Otherwise, feature flags are read from
// the "/users/myinfo/me" response.
window.setDevFeatureFlags = (devFeatureFlags) => {
    userInfoStore.setState({
        ...userInfoStore.getState(),
        devFeatureFlags,
    });
};
window.userInfoStore = userInfoStore;

export function useFeatureFlag(flag) {
    const userInfo = useUserInfo();

    return (userInfo?.features ?? []).includes(flag);
}
