// Resize function to be used externally when needed
export function resizeAllTextareas() {
    const textareas = document.querySelectorAll(
        'textarea[data-auto-resize="true"]'
    );
    textareas.forEach((textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    });
}

// Resize a specific textarea element.
function resizeTextarea(element) {
    if (
        element.tagName === 'TEXTAREA' &&
        element.dataset.autoResize === 'true'
    ) {
        const textarea = element;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
}

// Resize the textarea element referenced in an 'input' event.
function resizeTextareaFromInputEvent(ev) {
    resizeTextarea(ev.target);
}

// Global state for whether this feature is enabled.
let enabled = false;

// Toggle whether the resize is enbaled.
export default function setAutoResizeTextareaEnabled(newEnabled) {
    newEnabled = newEnabled ?? true; // Treat undefined as true.
    if (!enabled && newEnabled) {
        document.addEventListener('input', resizeTextareaFromInputEvent);
        enabled = true;
        resizeAllTextareas();
    }
    if (!newEnabled) {
        document.removeEventListener('input', resizeTextareaFromInputEvent);
        enabled = false;
    }
}
