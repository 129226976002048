import { throwNamedError } from '../components/Dashboard/GlobalErrorDisplay';

// Transform a function that would throw a generic error
// into a function that throws an error with a display name (i18n key).
// Useful for rendering messages such as "Error in treatment" or similar.
// Note that this does not cover async use cases.
export const throwsNamedError =
    (name, func) =>
    (...args) => {
        try {
            func(...args);
            // We could check for promise here if we wanted.
        } catch (error) {
            throwNamedError(name, error);
        }
    };
