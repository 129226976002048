import './FreeTextEditor.css';

import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import { OutputCardHeader } from '../Dashboard/OutputCardHeader';
import { useApiQuery } from '../../helpers/useApiQuery';
import { useTranslation } from 'react-i18next';
import { ModalRenderTarget } from '../Dashboard/ModalContext';
import { usePersistCallback } from '../../helpers/usePersist';
import { useSessionStorage } from '../../helpers/useStorage';

function suggestionToDomNode(suggestion, setActiveSuggestion) {
    if (suggestion.suggest != undefined) {
        const container = document.createElement('span');
        container.className = 'suggest-container';

        const node = document.createElement('span');
        node.className = 'suggest';
        node.appendChild(document.createTextNode(suggestion.text));
        container.appendChild(node);

        container.addEventListener('click', (ev) => {
            if (!node.classList.contains('suggest')) return;
            setActiveSuggestion({
                span: container,
                suggest: suggestion.suggest,
                accept: () => {
                    container.replaceWith(suggestion.suggest);
                    setActiveSuggestion(undefined);
                },
                dismiss: () => {
                    container.replaceWith(suggestion.text);
                    setActiveSuggestion(undefined);
                },
            });
            // Stop propagating event before it reaches the parent div,
            // as the parent div clears our active suggestion when clicked.
            ev.stopPropagation();
        });
        return container;
    } else {
        return suggestion.text; // Actually just a string but that's fine.
    }
}

function ActiveSuggestion({ suggestion }) {
    const { t } = useTranslation();

    const replaceText = t('replaceWith', { suggest: suggestion.suggest });

    return (
        <div contentEditable={false} className="active-suggest">
            <div>{replaceText}</div>
            <div>
                <button onClick={suggestion.accept}>{t('apply')}</button>
                <button onClick={suggestion.dismiss}>{t('dismiss')}</button>
            </div>
        </div>
    );
}

export function FreeTextEditor() {
    const [activeSuggestion, setActiveSuggestion] = useState();
    const divRef = useRef();
    const query = useApiQuery();
    const { t } = useTranslation();

    const [syncText, updateSyncText] = useSessionStorage('freetext', 'start');

    // Whenever a modal is opened/closed,
    // persist the current text to the session storage.
    usePersistCallback(() => {
        const newSyncText = divRef.current.innerText;
        updateSyncText(newSyncText);
    });

    // Whenever the session storage is updated, update the inner text.
    useEffect(() => {
        divRef.current.innerText = syncText;
    }, [syncText]);

    const handleDivClick = () => {
        setActiveSuggestion(undefined);
    };
    const handleGrammarCheck = () => {
        // When the button is clicked, submit a query.
        // This may or may not be ignored depending on debounce.
        query.submit({
            method: 'POST',
            path: '/grammar/annotate',
            body: { text: divRef.current.innerText },
            callback: (suggestions) => {
                divRef.current.replaceChildren(
                    ...suggestions.map((suggest) =>
                        suggestionToDomNode(suggest, setActiveSuggestion)
                    )
                );
            },
        });
    };

    // NOTE: we should avoid collecting annotation hover text.
    // That's a minor issue though.
    const copyText = () => divRef.current.innerText;

    // Note that because we are performing updates outside of react,
    // the <CopyIcon /> component doesn't really apply.
    return (
        <>
            <OutputCardHeader title={t('freeTextEditor')} copyText={copyText} />
            <div
                className="free-text-editor"
                ref={divRef}
                suppressContentEditableWarning
                contentEditable={!query.isLoading}
                onClick={handleDivClick}
            >
                {t('thisIsAFreeTextEditor')}
            </div>
            <div className="flex align-items-center flex-grow-0">
                <button onClick={handleGrammarCheck}>
                    {t('grammarCheck')}
                </button>
                {query.isLoading && <LoadingIcon />}
            </div>
            {activeSuggestion &&
                createPortal(
                    <ActiveSuggestion suggestion={activeSuggestion} />,
                    activeSuggestion.span
                )}
        </>
    );
}

export function FreeTextEditorCard() {
    return <ModalRenderTarget render={() => <FreeTextEditor />} />;
}
