import { useCodeDetails } from './IcdCodeDetailsCache';
import { IcdDisplay } from './IcdDisplay';
import { TYPE_MANUAL, TYPE_SELECTED, TYPE_SUGGESTED } from './constants';

// Transform an ICD-10 code into a breadcrumbs path,
// where each element of the path is editable.
// string -> string[]
function pathFromCode(code) {
    const [before, after] = code.split('.');
    if (!after) return [code];
    return [`${before}.`, ...after];
}

// Transform an <ICDDiagnosis> `onChangeCode` handler into
// a <Breadcrumbs> `onChangePath` handler.
const onChangeCodeToOnChangePath =
    ({ onChangeCode }) =>
    (newPath) => {
        // Technically this transform is a no-op (for now).
        // Weird oddity but oh well.
        onChangeCode(newPath);
    };

// Transform the value and onChange handler for the ICD-10 code
// into the corresponding items for the  breadcrumbs path.
function usePathController({ code, onChangeCode }) {
    const path = pathFromCode(code);

    const onChangePath = onChangeCodeToOnChangePath({ onChangeCode });

    return {
        path,
        onChangePath,
    };
}

// Controlled component for ICD display.
// Potential paths are considered as a separate prop
// from code details because they can be fetched in the background.
// In constrast, code description and notes should be available immediately.
export function IcdDiagnosis({
    code,
    codeBezeichnung,
    onChangeCode,
    onRemove,
    type,
    onApply,
    onDismiss,
    onEdit,
}) {
    const pathController = usePathController({ code, onChangeCode });
    const codeDetails = useCodeDetails(code);

    const fullName =
        type === TYPE_MANUAL ? codeBezeichnung : (codeDetails?.fullName ?? '');

    return (
        <IcdDisplay
            path={pathController.path}
            onChangePath={pathController.onChangePath}
            isLoading={type !== TYPE_MANUAL && !codeDetails?.potentialPaths}
            potentialPaths={
                type === TYPE_SELECTED && codeDetails?.potentialPaths
            }
            fullName={fullName}
            description={codeDetails?.description}
            notes={codeDetails?.notes ?? []}
            onEdit={type !== TYPE_SUGGESTED && onEdit}
            onRemove={type !== TYPE_SUGGESTED && onRemove}
            onApply={type === TYPE_SUGGESTED && onApply}
            onDismiss={type === TYPE_SUGGESTED && onDismiss}
            type={type !== TYPE_SELECTED && type}
        />
    );
}
