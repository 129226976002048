import { debounce } from './debounce';

// Wrap a function with an update callback that reports {data,error,isLoading}.
function withUpdateCallback(func, updateCallback) {
    return async function (...args) {
        updateCallback.bind(this)({ isLoading: true });
        let data;
        try {
            data = await func(...args);
        } catch (error) {
            updateCallback.bind(this)({ error, isLoading: false });
        }
        updateCallback.bind(this)({ data, isLoading: false });
    };
}

export function makeSyncLocalEditsAction({ doSync, onSync, updateCallback }) {
    let syncLocalEdits = async function (localEdits) {
        await doSync(localEdits);
        await onSync();
        return true;
    };
    // Wrap with update callback, and then debounce.
    // This guarantees that update callback is only called
    // for the call that actually makes it through debounce.
    syncLocalEdits = withUpdateCallback(syncLocalEdits, updateCallback);
    syncLocalEdits = debounce(syncLocalEdits);

    const syncLocalEditsWithEagerUpdateCallback = function (edits) {
        updateCallback.call(this, { isLoading: true });
        syncLocalEdits.bind(this)(edits);
    };
    return syncLocalEditsWithEagerUpdateCallback;
}
