import './ModalHeader.css';
import { useCloseModal } from 'components/Dashboard/ModalContext';
import { IoClose } from 'react-icons/io5';

export function ModalHeader({ title }) {
    const closeModal = useCloseModal();

    return (
        <div className="modal-header">
            <div onClick={closeModal} className="pallet-icon">
                <IoClose style={{ fontSize: 'var(--size-content-medium)' }} />
            </div>
            <span>{title}</span>
        </div>
    );
}
