import { useTranslation } from 'react-i18next';

import LoadingIcon from 'helpers/LoadingIcon.helpers';
import './DialogueTranscription.css';
import { useDialogueTransciptionController } from './DialogueController';
import { ZoomButton } from 'components/Dashboard/OutputCardHeader';

// Given [x1,x2,x3], get [f(x1,0),g(0),f(x2,1), g(1), ...]
// Useful for adding row barriers in grids.
function betweenMap(items, itemFunc, betweenFunc) {
    const newItems = [];
    for (let i = 0; i < items.length; ++i) {
        newItems.push(itemFunc(items[i], i));
        newItems.push(betweenFunc(i));
    }
    newItems.pop(); // Final barrier should be removed.

    return newItems;
}

function disabledClassName(root, disabled) {
    let className = root;
    if (disabled) {
        className += ` ${root}-disabled`;
    }
    return className;
}

function DialogueTranscriptionItem({ transcript, onChangeEnabled, enabled }) {
    const { t } = useTranslation();

    const disabled = !enabled;

    const handleClickToggle = () => {
        onChangeEnabled(!enabled);
    };

    return (
        <>
            <div
                key={transcript}
                className={disabledClassName(
                    'dialogue-transcript-item',
                    disabled
                )}
            >
                {transcript}
            </div>
            <div
                className={disabledClassName('dialogue-grid-button', disabled)}
            >
                <button onClick={handleClickToggle}>
                    {t(enabled ? 'remove' : 'keep')}
                </button>
            </div>
        </>
    );
}

// Component to render dialogue transcription.
// Requires a `useDialogueTranscription()` hook,
// which is passed in by the parent component.
export function DialogueTranscription() {
    const { localTranscriptItems, handleChangeEnabled } =
        useDialogueTransciptionController();

    return (
        <div className="dialogue-transcript">
            {betweenMap(
                localTranscriptItems,
                ({ transcript, enabled }) => (
                    <DialogueTranscriptionItem
                        key={transcript}
                        transcript={transcript}
                        enabled={enabled}
                        onChangeEnabled={handleChangeEnabled(transcript)}
                    />
                ),
                (idx) => (
                    <hr key={idx} className="dialogue-divider" />
                )
            )}
        </div>
    );
}

export function DialogueTranscriptionHeaderElements({ hook }) {
    const { t } = useTranslation();
    const { dirty, applyChangesToServer } = useDialogueTransciptionController();

    return (
        <>
            {dirty && (
                <button onClick={applyChangesToServer}>
                    {t('applyRemovals')}
                </button>
            )}
            {false && hook.queryIsLoading && <LoadingIcon />}
        </>
    );
}

export function TranscriptionCard() {
    return (
        <>
            <div className="heading gap-10px dialogue-heading">
                <DialogueTranscriptionHeaderElements />
                <ZoomButton />
            </div>
            <DialogueTranscription />
        </>
    );
}
