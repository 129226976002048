import React, { useState, useEffect, useContext, useCallback } from 'react';
import { PatientContext, getAllPatients } from './PatientContext';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import './PatientSelector.css';
import { useRESTRequest } from './serverUtils';
import { useTranslation } from 'react-i18next';
import { resizeAllTextareas } from '../../useAutoResizeTextareaHook';
import { PatientSelectorPopup } from './PatientSelectorPopup';
import { useOpenCloseKey } from './openClosePopup';

//Possible TODO: create a global refresh rate that refreshes/checks many important items at a set rate
const FETCH_PATIENTS_REFRESH_RATE = 60000;

export const PatientSelector = () => {
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);
    const popup = useOpenCloseKey();

    const {
        state: { patientId, physicianInput },
        dispatch,
    } = useContext(PatientContext);
    const { t } = useTranslation();

    const makeRESTRequest = useRESTRequest();

    const fetchPatients = useCallback(() => {
        (async () => {
            const patients = await getAllPatients(makeRESTRequest);
            setPatients(patients);
        })();
    }, [makeRESTRequest]);

    useEffect(fetchPatients, [fetchPatients]);

    useEffect(() => {
        const interval = setInterval(
            fetchPatients,
            FETCH_PATIENTS_REFRESH_RATE
        );
        return () => clearInterval(interval);
    }, [fetchPatients]);

    const labelFromPatientInfo = (patient) => {
        const patientInfo = patient.info;
        const id = patient.id;

        if (patient.firstName || patient.lastName) {
            return (
                `${patient.firstName} ${patient.lastName} ` +
                `- ${patient.age} / ${patient.gender} - ${id}`
            );
        }

        if (!patientInfo) {
            return t('newPatient', { id });
        }

        return t('patientLabel', patientInfo);
    };

    const openPopup = popup.open;
    const handlePatientSelected = useCallback(
        async (event) => {
            setLoading(true);

            const selectedValue = event.target.value;
            if (
                !physicianInput.dirty ||
                window.confirm('Discard changes to the current patient?')
            ) {
                if (selectedValue === 'new') {
                    openPopup();
                    setLoading(false);
                    // After selecting the "new patient" item,
                    // revert to the "select a patient" item.
                    // This lets us try again if the popup is closed,
                    // since <select onChange={...} /> only
                    // fires events on changes.
                    dispatch({
                        type: 'SET_PATIENT_ID',
                        payload: '',
                    });
                } else {
                    dispatch({
                        type: 'SET_PATIENT_ID',
                        payload: selectedValue,
                    });
                }
                setLoading(false);
            }
        },
        [dispatch, physicianInput.dirty, openPopup]
    );

    useEffect(() => {
        if (patientId) {
            resizeAllTextareas(); // Resize all textareas after patientId changes
        }
    }, [patientId]);

    const handleCreate = ({ patientId }) => {
        dispatch({
            type: 'SET_PATIENT_ID',
            payload: patientId,
        });

        fetchPatients();
    };

    return (
        <div className="patient-selector">
            {loading && <LoadingIcon />}
            <select value={patientId} onChange={handlePatientSelected}>
                <option value="">--{t('selectAPatient')}--</option>
                <option value="new" className="add-new-session-button">
                    {t('createNewSession')}
                </option>
                {patients.map((patient) => (
                    <option key={patient.id} value={patient.id}>
                        {labelFromPatientInfo(patient)}
                    </option>
                ))}
            </select>
            {popup.isOpen && (
                <PatientSelectorPopup
                    key={popup.key}
                    onCreate={handleCreate}
                    onClose={popup.close}
                />
            )}
        </div>
    );
};
