// Form which automatically connects inputs to a big state field.

import { createContext, useContext } from 'react';

import './ReactiveForm.css';

const FormContext = createContext();

export function Form({ children, state, setState }) {
    const setField = (fieldName, value) => {
        setState({
            ...state,
            [fieldName]: value,
        });
    };

    return (
        <FormContext.Provider value={{ setField, fields: state }}>
            {children}
        </FormContext.Provider>
    );
}

export function Input({ fieldName, ...props }) {
    const { fields, setField } = useContext(FormContext);

    const handleChange = (ev) => {
        setField(fieldName, ev.target.value);
    };

    return (
        <input
            className="reactive-form-input"
            {...props}
            value={fields[fieldName]}
            onChange={handleChange}
        />
    );
}
