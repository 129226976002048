import React from 'react';
import './AccountDetails.css';
import { FaUserDoctor } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '../../../helpers/useUserInfo';
import {
    Dropdown,
    DropdownProvider,
    useToggleDropdown,
} from 'helpers/Dropdown';
import { withProvider } from 'helpers/withProvider';
import { useSetModalRender } from 'components/Dashboard/ModalContext';
import { UserPreferences } from '../UserPreferences';
import { AccountInfo } from '../AccountInfo';

export const AccountDetails = withProvider(({ onLogout }) => {
    const userInfo = useUserInfo();
    const { t } = useTranslation();
    const toggleDropdown = useToggleDropdown();
    const setModalRender = useSetModalRender();

    const translatedRole = userInfo.role && t(userInfo.role);

    const labelFromUserInfo = () => {
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
            <>
                <div className="user-name">{`${userInfo.title} ${userInfo.firstName} ${userInfo.lastName}`}</div>
                <div className="user-role">
                    {capitalizeFirstLetter(translatedRole)}
                </div>
            </>
        );
    };

    return (
        <div className="account-details-container">
            <button className="account-details-button" onClick={toggleDropdown}>
                <FaUserDoctor />
                <div className="account-content">{labelFromUserInfo()}</div>
            </button>
            <Dropdown
                items={[
                    {
                        label: t('preferences'),
                        callback: () => {
                            setModalRender(() => <UserPreferences />);
                        },
                    },
                    {
                        label: t('accountInfo'),
                        callback: () => {
                            setModalRender(() => <AccountInfo />);
                        },
                    },
                    {
                        label: t('help'),
                        callback: () => {
                            window.open('/wiki', '_blank').focus();
                        },
                    },
                    { label: t('logOut'), callback: onLogout },
                ]}
            />
        </div>
    );
}, DropdownProvider);
