import './PracticeDashboard.css';
import { LeftColumn } from './LeftColumn';
import { RightColumn } from './RightColumn';
import { ModalDisplay } from '../Dashboard/ModalContext';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { PatientContext } from '../Dashboard/PatientContext';
import { patientSelected } from 'helpers/PatientSelected.helpers';
import { PlaceholderProvider } from 'components/Dashboard/PlaceholderContext';
import { createCardElements } from './cardConfig';
import { useRefs } from '../../helpers/refsObject';
import setAutoResizeTextareaEnabled from 'useAutoResizeTextareaHook';

const PracticeDashboard = () => {
    const { t } = useTranslation();
    const { state } = useContext(PatientContext);

    // Always enable the global textarea resize feature
    // if we are rendering this component.
    // Becomes a no-op after the first render.
    setAutoResizeTextareaEnabled(true);

    // Currently, we hold refs to all child cards.
    // However, we may only need to pass handlers.
    // We'll see once we actually get resize integrated.
    const refs = useRefs();
    const cards = createCardElements(t, refs);
    const layout = {
        leftColumn: cards.slice(0, 2),
        rightColumn: cards.slice(2),
    };

    return (
        <PlaceholderProvider>
            <div
                className={`practice-dashboard ${!patientSelected(state.patientId) ? 'disabled' : ''}`}
            >
                <LeftColumn components={layout.leftColumn} />
                <RightColumn components={layout.rightColumn} />
            </div>
            <ModalDisplay />
        </PlaceholderProvider>
    );
};

export default PracticeDashboard;
