// <AudioRecorder> component to handle streaming audio recording.
// Not responsible for any kind of server logic.
// Since this component generalizes over streaming
// and non-streaming use-cases, it is best to use
// one of the wrappers such as <RecordButton>
// or <TranscribeButton>, which implement
// per-chunk handlers so you don't have to think about it.

import { FaFloppyDisk, FaMicrophone, FaPause, FaPlay } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import {
    useAudioRecorderController,
    useAudioRecorderTimerController,
} from './audio/AudioRecorderController';
import './AudioRecorder.css';
import { STATE_PAUSED, STATE_RECORDING } from './ChunkedMediaRecorder';
import LoadingIcon from './LoadingIcon.helpers';
import { useTimerCurrentTime } from './useTimer';
import Tooltip from '../components/Dashboard/Tooltip';

// Extra state (not present in MediaRecorder),
// used when we are displaying a loading icon.
const STATE_PROCESSING = 'processing';

export function useAudioRecorder() {} // For compatibility reasons.

// Given a function that checks the current (relative)
// time in milliseconds, display a timer.
function Timer({ getTimeMs }) {
    const ms = useTimerCurrentTime({
        getTimeMs,
        pollMs: 500,
    });

    const totalSeconds = Math.floor(ms / 1_000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    const displayText =
        String(minutes).padStart(2, '0') +
        ':' +
        String(seconds).padStart(2, '0');

    return <span>{displayText}</span>;
}

function AudioRecorderTimer() {
    const { current } = useAudioRecorderTimerController();
    return <Timer getTimeMs={current} />;
}

function PlayPauseButton({ onClick, state }) {
    const IconClass = state === STATE_RECORDING ? FaPause : FaPlay;
    const toolTipText = state === STATE_RECORDING ? 'pause' : 'play';

    return (
        <Tooltip text={toolTipText}>
            <button onClick={onClick}>{<IconClass />}</button>
        </Tooltip>
    );
}

export function AudioRecorder({
    MediaRecorderClass = MediaRecorder,
    // Factory function that creates a set of handlers
    // for a particular recording.
    handleRecording,
    timeSliceMs,
    disabled,
}) {
    const {
        // State
        ready,
        state,
        // Methods
        cancel,
        playPause,
        save,
        startRecord,
    } = useAudioRecorderController({
        MediaRecorderClass,
        timeSliceMs,
        makeHandlers: handleRecording,
    });

    return (
        ready && (
            <span
                className={`audio-recorder ${
                    [STATE_RECORDING, STATE_PAUSED].includes(state)
                        ? 'active'
                        : ''
                }`}
            >
                {[STATE_RECORDING, STATE_PAUSED].includes(state) ? (
                    <>
                        <Tooltip text="cancel">
                            <button onClick={cancel}>
                                <IoClose
                                    style={{
                                        fontSize: 'var(--size-content-medium)',
                                    }}
                                />
                            </button>
                        </Tooltip>
                        <AudioRecorderTimer />
                        <PlayPauseButton state={state} onClick={playPause} />
                        <Tooltip text="save">
                            <button onClick={save}>
                                <FaFloppyDisk />
                            </button>
                        </Tooltip>
                    </>
                ) : (
                    <button
                        onClick={startRecord}
                        disabled={disabled}
                        className={`process-button ${!disabled ? 'active' : 'inactive'}`}
                    >
                        <FaMicrophone />
                    </button>
                )}
                {state === STATE_PROCESSING && <LoadingIcon />}
            </span>
        )
    );
}
