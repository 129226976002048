// Wrap a function such that it can only be called once at a time.

import { Observable } from './Observable';

// Extra calls will be ignored.
export function withBottleneck(func) {
    let busy = new Observable(false);
    const wrappedFunc = async (...args) => {
        if (!busy.get()) {
            busy.update(true);
            try {
                return await func(...args);
            } finally {
                busy.update(false);
            }
        }
    };
    wrappedFunc.busy = busy;
    return wrappedFunc;
}
