import { useRef, useState } from 'react';
import LoadingIcon from '../../../helpers/LoadingIcon.helpers';
import { Breadcrumbs } from './Breadcrumbs';
import { TYPE_MANUAL } from './constants';
import './IcdDisplay.css';

// Display component for display of an ICD diagnosis code.
export function IcdDisplay({
    path,
    potentialPaths,
    onChangePath,
    fullName,
    type,
    isLoading,
    onRemove,
    onApply,
    onDismiss,
    onEdit,
}) {
    const [edits, setEdits] = useState(false);
    const fullNameInputRef = useRef();

    const handleClickEdit = () => {
        setEdits({
            code: path.join(''),
            codeBezeichnung: fullName,
        });
        fullNameInputRef.current.focus();
    };

    const handleClickSave = () => {
        onEdit({ ...edits, type: TYPE_MANUAL });
        setEdits(undefined);
    };

    const handleChangeFullName = (ev) => {
        setEdits({
            ...edits,
            codeBezeichnung: ev.target.value,
        });
    };

    const handleChangeCode = (ev) => {
        setEdits({
            ...edits,
            code: ev.target.value,
        });
    };

    return (
        <div className="icd-display">
            <div className="icd-display-header">
                {edits ? (
                    <input
                        className="code"
                        value={edits.code}
                        onChange={handleChangeCode}
                    />
                ) : (
                    <Breadcrumbs {...{ path, potentialPaths, onChangePath }} />
                )}
                <input
                    ref={fullNameInputRef}
                    className="fullname"
                    value={edits?.codeBezeichnung ?? fullName}
                    readOnly={!edits}
                    onChange={handleChangeFullName}
                />
                {isLoading && (
                    <LoadingIcon iconClassName="rotating-icon no-vert-margin" />
                )}
                {type && <span className="icd-display-type">[{type}]</span>}
                <span className="flex-grow-1" />
                {onEdit && !edits && (
                    <button onClick={handleClickEdit}>Edit</button>
                )}
                {edits && <button onClick={handleClickSave}>Save</button>}
                {onRemove && <button onClick={onRemove}>Remove</button>}
                {onDismiss && <button onClick={onDismiss}>Dismiss</button>}
                {onApply && <button onClick={onApply}>Apply</button>}
            </div>
        </div>
    );
}
