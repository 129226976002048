import { useCallback, useState } from 'react';

// Open and close some kind of component. Each `open()` call updates the key.
// As long this key used like <SomeComponent key={key} />,
// each time the component is opened, its state will be completely reset.
export function useOpenCloseKey() {
    const [key, setKey] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        key,

        close: () => {
            setIsOpen(false);
        },
        open: useCallback(() => {
            setIsOpen(true);
            setKey((key) => key + 1);
        }, [setIsOpen, setKey]),
    };
}
