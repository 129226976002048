// Control the dialogue outside of react. Then subscribe to the state from react.
// This makes it easy to link components in very different parts of the tree
// (modal/non-modal) to the same state/effects.

import {
    Observable,
    useObservable,
    computedProperty,
} from 'helpers/Observable';
import { queryApi } from 'helpers/apiQuery';
import { withBottleneck } from 'helpers/withMutex';
import { useEffect } from 'react';
import { memoize } from 'helpers/memoize';

export class DialogueTranscriptionController {
    constructor({ serverResponse, patientId }) {
        this.serverResponse = serverResponse;
        this.patientId = patientId;

        this.localTranscriptItems = new Observable([]);
        // Has the local list of transcript items been modified?
        this.dirty = new Observable(false);

        // When the server response is updated,
        // overwrite the local items entirely.
        this.serverResponse.addListener((serverResponse) => {
            this.localTranscriptItems.update(
                (serverResponse?.transcript ?? []).map((transcript) => ({
                    transcript,
                    enabled: true,
                }))
            );
        });
    }

    async applyChangesToServer() {
        const keptTranscripts = this.localTranscriptItems
            .get()
            .filter((x) => x.enabled)
            .map((x) => x.transcript);

        const response = await queryApi(
            `/dialogue/transcript/${this.patientId.get()}`,
            {
                method: 'PUT',
                json: { transcript: keptTranscripts },
            }
        );

        this.dirty.update(false);
        this.serverResponse.update(await response.json());
    }

    setTranscriptItemEnabled(transcript, enabled) {
        this.localTranscriptItems.update(
            this.localTranscriptItems
                .get()
                .map((x) =>
                    x.transcript === transcript ? { ...x, enabled } : x
                )
        );
        this.dirty.update(true);
    }
}

const bind = (func) => func.bind(this);

export class DialogueController {
    constructor() {
        this.patientId = new Observable();
        this.serverResponse = new Observable();
        this.processingIsStale = new Observable(false);

        this.onChangePhysicianInput = undefined;
        this.onError = undefined;

        this.dialogueTranscriptionController =
            new DialogueTranscriptionController({
                serverResponse: this.serverResponse,
                patientId: this.patientId,
            });

        // Every time the server response is updated,
        // mark processing as stale.
        this.serverResponse.addListener(() => {
            this.processingIsStale.update(true);
        });
        this.patientId.addListener(() => {
            this.serverResponse.update(undefined);
        });
    }

    setPatientId(value) {
        this.patientId.update(value);
    }

    setOnChangePhysicianInput(handler) {
        this.onChangePhysicianInput = handler;
    }
    setOnError(handler) {
        this.onError = handler;
    }

    // Fetch info on whether the transcript is currently sufficient in length.
    fetchTranscriptSufficiency = bind(
        withBottleneck(async () => {
            const response = await queryApi(
                `/dialogue/transcript-sufficiency/${this.patientId.get()}`
            );
            const body = await response.json();
            this.serverResponse.update(body);
        })
    );

    // Save a single recording to the database.
    async processRecording(transcripts) {
        const response = await queryApi(`/dialogue/${this.patientId.get()}`, {
            method: 'POST',
            json: { transcripts },
        });
        const body = await response.json();
        this.serverResponse.update(body);
    }

    // Are we currently able to process recordings?
    canProcessRecordings = memoize(() => {
        return computedProperty(() => {
            const isStale = this.processingIsStale.get();
            const serverResponse = this.serverResponse.get();

            // Check if all required states are stable before determining the value.
            if (isStale === undefined || serverResponse === undefined) {
                return false;
            }

            return Boolean(isStale && !serverResponse?.insufficientContent);
        }, [this.processingIsStale, this.serverResponse]);
    });

    // Summarize multiple recordings into one transcript
    // and update downstream fields.
    processRecordings = withBottleneck(async () => {
        if (!this.canProcessRecordings().get()) {
            alert(
                'Insufficient Content Recorded: Please record more content to continue'
            );
            return;
        }

        try {
            const response = await queryApi(
                `/dialogue/${this.patientId.get()}/process-transcript`,
                { method: 'POST' }
            );
            const body = await response.json();
            this.processingIsStale.update(false);
            this.onChangePhysicianInput(body.physicianInput);
        } catch (error) {
            if (this.onError) {
                this.onError(error);
            } else {
                console.error(error);
            }
        }
    });

    isProcessing = memoize(() => {
        return computedProperty(
            () => this.processRecordings.busy.get(),
            [this.processRecordings.busy]
        );
    });
}

// Singleton for now. Can be separated into multiple instances if necessary.
const dialogueController = new DialogueController();
window.dialogueController = dialogueController;

export function useDialogueController({
    patientId,
    onChangePhysicianInput,
    onError,
}) {
    useEffect(() => {
        dialogueController.setPatientId(patientId);
    }, [patientId]);
    dialogueController.setOnChangePhysicianInput(onChangePhysicianInput);
    dialogueController.setOnError(onError);

    return {
        canProcessRecordings: useObservable(
            dialogueController.canProcessRecordings()
        ),
        processRecording:
            dialogueController.processRecording.bind(dialogueController),
        processRecordings:
            dialogueController.processRecordings.bind(dialogueController),
        fetchTranscriptSufficiency:
            dialogueController.fetchTranscriptSufficiency,
        setPatientId: dialogueController.setPatientId.bind(dialogueController),
        isProcessing: useObservable(dialogueController.isProcessing()),
    };
}

export function useDialogueTransciptionController() {
    const controller = dialogueController.dialogueTranscriptionController;

    return {
        dirty: useObservable(controller.dirty),
        localTranscriptItems: useObservable(controller.localTranscriptItems),
        handleChangeEnabled: (transcript) => (enabled) => {
            controller.setTranscriptItemEnabled(transcript, enabled);
        },
        applyChangesToServer: controller.applyChangesToServer.bind(controller),
    };
}
