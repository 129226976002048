import { useRef } from 'react';

export function useRefs() {
    const objectRef = useRef({});

    return {
        get: (refName) => (element) => {
            objectRef.current[refName] = element;
        },
    };
}
