// Component to display editable account details modal.

import { refreshUserInfo, useUserInfo } from 'helpers/useUserInfo';
import * as ReactiveForm from 'helpers/ReactiveForm';
import { makeEnhancedStore } from 'helpers/EnhancedStore';
import { makeSyncLocalEditsAction } from 'helpers/syncEditsAction';
import { queryApi } from 'helpers/apiQuery';
import { queryStateToStatus } from 'helpers/queryStateToStatus';
import { LocalChangesStatusDisplay } from 'components/Dashboard/LocalChanges';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';

// Account info uses a global store because each page session
// is only ever tied to one account.
const useAccountInfoStore = makeEnhancedStore({
    initStore: () => ({}),
    computeProperties: (state) => ({
        localChangesStatus: queryStateToStatus(state.syncQuery),
    }),
    actions: {
        async setLocalAccountEdits(localAccountEdits) {
            // Update state immediately to render form properly.
            this.setState({
                ...this.getState(),
                localAccountEdits: localAccountEdits,
            });
            await this.actions.syncLocalAccountEdits(localAccountEdits);
        },

        syncLocalAccountEdits: makeSyncLocalEditsAction({
            doSync: (localAccountEdits) =>
                queryApi('users/myinfo/set-account-info', {
                    method: 'POST',
                    json: localAccountEdits,
                }),
            updateCallback: function (syncQuery) {
                this.setState({
                    ...this.getState(),
                    syncQuery,
                });
            },
            onSync: refreshUserInfo,
        }),
    },
});

export function AccountInfo() {
    const { t } = useTranslation();
    const userInfo = useUserInfo();
    const accountInfoStore = useAccountInfoStore();

    const localAccountEdits = accountInfoStore.localAccountEdits ?? userInfo;

    if (!localAccountEdits) {
        return t('loading');
    } else {
        return (
            <div>
                <ModalHeader title={t('accountInfo')} />
                <ReactiveForm.Form
                    state={localAccountEdits}
                    setState={accountInfoStore.actions.setLocalAccountEdits}
                >
                    <div className="input-grid">
                        <label>{t('firstName')}</label>
                        <ReactiveForm.Input fieldName="firstName" />
                        <label>{t('lastName')}</label>
                        <ReactiveForm.Input fieldName="lastName" />
                    </div>
                    <div className="user-preferences-status-container">
                        <LocalChangesStatusDisplay
                            state={accountInfoStore.localChangesStatus}
                        />
                    </div>
                </ReactiveForm.Form>
            </div>
        );
    }
}
