import React, { useContext, forwardRef } from 'react';
import { PatientContext } from '../../Dashboard/PatientContext';
import {
    SearchBarBody,
    SearchBarHeader,
    SearchBarProvider,
} from '../SearchBar';
import { useApiQuery } from '../../../helpers/useApiQuery';
import { OutputCardProvider } from '../../Dashboard/OutputCard';

import { ModalRenderTarget } from '../../Dashboard/ModalContext';
import './DiagnosisCard.css';
import { ContextualOutputCardHeader } from '../../Dashboard/OutputCardHeader';

// Main component of diagnosis card, which is a child of OutputCardContextProvider.
const InnerDiagnosisCard = forwardRef((props, ref) => {
    const {
        state: { physicianInput },
        dispatch,
    } = useContext(PatientContext);

    // For use in search bar. However, we allocate these here
    // such that we can track whether they are loading.
    const suggestedDiagnosesQuery = useApiQuery();
    const availableDiagnosesQuery = useApiQuery();

    const handleDiagnosisSelect = (diagnosis) => {
        dispatch({ type: 'APPEND_DIAGNOSIS', payload: diagnosis });
    };

    return (
        <div ref={ref} className="card">
            <ContextualOutputCardHeader />
            {/* We build the search bar out of its components manually.
                This ensures that the searchbar body is scrollable,
                but the header is not. */}
            <SearchBarProvider
                onDiagnosis={handleDiagnosisSelect}
                selectedItems={physicianInput.diagnoses}
                suggestedDiagnosesQuery={suggestedDiagnosesQuery}
                availableDiagnosesQuery={availableDiagnosesQuery}
            >
                <SearchBarHeader />
                <div className="diagnosis-card-body">
                    <SearchBarBody />
                </div>
            </SearchBarProvider>
        </div>
    );
});

// Component we actually export,
// with all bells and whistles attached / wired up.
const DiagnosisCard = forwardRef((props, ref) => {
    return (
        <ModalRenderTarget
            render={() => (
                <OutputCardProvider
                    title={props.title}
                    category={props.category}
                    showHeader={props.showHeader}
                >
                    <InnerDiagnosisCard {...props} ref={ref} />
                </OutputCardProvider>
            )}
        />
    );
});

DiagnosisCard.displayName = 'DiagnosisCard';
InnerDiagnosisCard.displayName = 'InnerDiagnosisCard';

export default DiagnosisCard;
