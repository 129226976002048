// Context/provider to monitor local changes and sync them to the server.

import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useApiQuery } from '../../helpers/useApiQuery';
import { debounce } from '../../helpers/debounce';
import './LocalChanges.css';

export const STATE_NOCHANGE = 'nochange';
export const STATE_UNSAVED = 'unsaved';
export const STATE_SAVED = 'saved';
export const STATE_SAVING = 'saving';
export const STATE_ERROR = 'error';

export const Context = createContext();

export function Provider({ children, localState, onSaveToServer }) {
    const [state, setState] = useState(STATE_NOCHANGE);
    const query = useApiQuery();

    // Memoize the debounce functionality,
    // and take the new local state as an explicit argument.
    // This is done because we want to keep the memoized function
    // even when the local state changes.
    const submitQuery = query.submit;
    const debouncedSubmit = useMemo(
        () =>
            debounce((newLocalState) => {
                setState(STATE_SAVING);
                submitQuery({
                    method: 'POST',
                    path: '/patients/physician-input/',
                    body: newLocalState,
                    callback: (data) => {
                        if (data) {
                            setState(STATE_SAVED);
                            onSaveToServer();
                        } else {
                            setState(STATE_ERROR);
                        }
                    },
                    errorCallback: () => {
                        setState(STATE_ERROR);
                    },
                });
            }, 3_000),
        [submitQuery, setState, onSaveToServer]
    );

    // When the local state changes, trigger the debounced submit function
    // but to not re-memoize it.
    const handleChange = useCallback(() => {
        if (localState.physicianInput.dirty) {
            setState(STATE_UNSAVED);
            debouncedSubmit(localState);
        } else {
            setState(STATE_NOCHANGE);
        }
    }, [debouncedSubmit, localState]);

    // Automatically re-sync changes whenever its dependencies are updated.
    useEffect(handleChange, [handleChange]);

    return (
        <Context.Provider value={{ state, saveChanges: handleChange }}>
            {children}
        </Context.Provider>
    );
}

export function LocalChangesStatusDisplay({ state }) {
    return (
        <span className="local-changes-status">
            {state === STATE_SAVED && 'Saved changes'}
            {[STATE_UNSAVED, STATE_SAVING].includes(state) &&
                'Saving changes...'}
            {state === STATE_ERROR && 'Error saving'}
        </span>
    );
}

export function LocalChangesStatus() {
    const { state } = useContext(Context);

    return <LocalChangesStatusDisplay state={state} />;
}
